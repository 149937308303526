// Navigation Bar SECTION
const navBar = {
  show: true,
};

// Main Body SECTION
const mainBody = {
  gradientColors: "#37AFE1,#4CC9FE,#87A2FF", //"#046f92,#047f99,#046d84,#045f73",
  firstName: "Aiden",
  middleName: "",
  lastName: "Robinson",
  message: "Bringing ideas to life.", //"Code-driven, solution-focused, passion-infused.",
  icons: [
    {
      image: "fa-github",
      url: "https://github.com/Aiden-Robinson",
    },
    {
      image: "fa-linkedin",
      url: "https://www.linkedin.com/in/aiden-robinson/",
    },
  ],
};

// ABOUT SECTION
// If you want the About Section to show a profile picture you can fill the profilePictureLink either with:
//a) your Instagram username
//      i.e:profilePictureLink:"johnDoe123",
//b) a link to an hosted image
//      i.e:profilePictureLink:"www.picturesonline.com/johnDoeFancyAvatar.jpg",
//c) image in "config" directory and use require("") to import here,
//      i.e: profilePictureLink: require("../config/hashirshoaeb.png"),
//d) If you do not want any picture to be displayed, just leave it empty :)
//      i.e: profilePictureLink: "",
// For Resume either provide link to your resume or import from "config" directory
//     i.e resume: require("../config/resume.pdf"),
//         resume: "https://docs.google.com/document/d/13_PWdhThMr6roxb-UFiJj4YAFOj8e_bv3Vx9UHQdyBQ/edit?usp=sharing",

const about = {
  show: true,
  heading: "About Me",
  imageLink: require("../config/headshot3.jpeg"),
  imageSize: 375,
  message: `Hi, I’m Aiden, a Mechatronics Engineering & Management student with a strong passion for AI, cloud computing, and embedded systems.`,
  extraMessage: `With 16 months of experience, I’ve worked on AI-driven projects in predictive maintenance for network equipment, energy optimization for data centers, computer vision models, generative AI automations, data governance, data analysis, and CI/CD pipelines. My hands-on hardware experience comes from 3 years with McMaster’s Formula Electric Race Car team, where I designed and manufactured PCBs, and tested, attached, and documented wire harness connections.

I believe that communication is key to successful teamwork. With 4 years of leadership experience as a manager at Ontario Parks, I bring this value into my roles. I currently serve as the VP of Technical at McMaster Artificial Intelligence Society, where I oversee several technical teams and the educational workshop division. I also contribute as a teaching assistant for the faculty of engineering.  When I’m not coding or prototyping, I’m exploring the world of synthesizers and music production. I’m excited to bring my technical skills and innovative approach to new challenges in software and hardware engineering.`,
  resume: require("../config/Aiden_Robinson_Resume.pdf"),
};

// PROJECTS SECTION
// Setting up project length will automatically fetch your that number of recently updated projects, or you can set this field 0 to show none.
//      i.e: reposLength: 0,
// If you want to display specfic projects, add the repository names,
//      i.e ["repository-1", "repo-2"]
const repos = {
  show: true,
  calendar: true,
  heading: "Projects",
  gitHubUsername: "Aiden-Robinson",
  reposLength: 6,
  specificRepos: [],
  // specificRepos: ["Island-Generator", "gameoflife", "WCSS-Peer-Eval", "intellectus2-0"],
};

// Personal Projects SECTION
const projects = {
  show: true,
  heading: "Software Projects",
  data: [
    {
      title: 'Spincard',
      img: require('../assets/img/projects/spincard.png'),
      repo: "https://github.com/spin-card/spincard",
      bulletpoints: [
        "Developing a mobile app that allows small groups to create virtual credit cards and split expenses automatically",
        "A serverless, microservice-based platform for pooling and spending money. I worked on backend development, including database setup, migrations, GraphQL server, and cloud deployment",

        "Built with Go, GraphQL, PostgreSQL, Clerk, Encore, and React Native", 
      ],
    },
    {
      title: 'AR.cade, Hack Western Winner',
      img: require('../assets/img/projects/arcade_winner.png'),
      extraDesc: 'Devpost',
      link: "https://devpost.com/software/ar-cade",
    
      bulletpoints: [
        "Uses OpenCV and Google Mediapipe to detect user movements, allowing players to control classic games like Temple Run and Super Mario through body gestures.",
        "Developed a custom body-part movement algorithm to map gestures from detected positions (e.g., raising an arm) to specific in-game actions",
        "Tackled embedding real-time video with body tracking overlay into the frontend using API calls, managing latency with threading optimizations",
        "Placed 3rd overall of 70 total projects",
      ],
    },
    {
      title: 'Locker as a Service, DeltaHacks Winner',
      img: require('../assets/img/projects/laas_winner.png'),
      extraDesc: 'Devpost',
      link: "https://devpost.com/software/laas",
      
      bulletpoints: [
        "Provides a secure, IoT-enabled food locker solution controlled via a mobile app, allowing remote lock/unlock functionality with biometric access and temperature control for safe, contactless food delivery",
        "Built with a Raspberry Pi, Firebase, Python, C++, and Flutter, utilizing real-time IoT data to control locker functions, including a servo motor and temperature settings",
        "Placed 3rd overall of 95 total projects",
      ],
    },
    {
      title: 'Computer Vision Touchless Keyboard',
      img: require('../assets/img/projects/touchlessKeyboard.png'),
      repo: "https://github.com/Aiden-Robinson/Touchless-Keyboard-with-OpenCV",
      bulletpoints: [
        "Developed a touchless keyboard that uses computer vision to detect finger strokes and plays musical notes via a buzzer controlled by an Arduino",
        "Utilized Google’s MediaPipe framework for hand tracking with 21 landmarks and OpenCV for capturing webcam data and visual feedback. Landmark coordinates were converted into screen ratios to accurately detect finger positions",
        " Established serial communication between Python and Arduino, sending note data from Python to trigger corresponding buzzer frequencies on the Arduino",
      ],
    },
    
    {
      title: 'Embedded Pacemaker',
      img: require('../assets/img/projects/pacemaker.png'),
      
      repo: "https://github.com/Primo-Wang/3K04_Pace_Maker",
      bulletpoints: [
        "Created a Hardware in the Loop and Software in the Loop pacemaker to represent dynamic pacing modes of a pacemaker using accelerometer data on an STM32 microcontroller",
        "Data framing, data processing, and stateflows were implemented in Simulink",
        "Supports 8 pacing modes: (AOO,VOO,AAI,VVI,AOOR,VOOR,AAIR,VVIR,DDDR)",
        "Frontend created in Python and connects via UART protocol, including a real time electrocardiogram",

      ],
    },
    {
      title: 'Segment Display Neural Network',
      img: require('../assets/img/projects/segmentDisplay.png'),
      repo: "https://github.com/Aiden-Robinson/Segment-Display-Recognition-Neural-Network-in-C-",
      bulletpoints: [
        "Developed a segment display recognition neural network from scratch in C++ that identifies digits 0-9 based on varying brightness and incomplete segments",
        "The model is a fully connected feedforward network with an input layer, hidden layer, and output layer, using sigmoid activation functions. The 7 inputs represent segment brightness levels, and 10 outputs indicate confidence levels for each digit",
        "Trained using backpropagation for 3,000 epochs with an initial set of ideal patterns, achieving a training error of 0.003",
      ],
    },
    {
      title: "Second Brain For Students, CUCAI '24",
      img: require('../assets/img/projects/CUCAI.jpeg'),
      repo:"https://github.com/McMasterAI/Second-Brain",
      bulletpoints: [
        "Led a team of 6 to develop \"SecondBrain,\" an NLP-based AI assistant to help students manage and query personal and academic documents efficiently",
        "Developed with LangChain, Pinecone VDB, React.js, Flask, OpenAI API – Users upload documents (PDF/Docx), which are processed, vectorized, and stored in a unique database. When users ask questions, the system retrieves relevant sections using similarity search and GPT-4 for clear answers",
        "Reduced token costs by 45% through efficient data management and API integration",
      ],
    },
    {
      title: 'Sentiment Analysis Neural Network',
      img: require('../assets/img/projects/sentimentAnalysis.png'),
      repo:"https://github.com/Aiden-Robinson/Sentiment-Analysis-Neural-Network-with-Tensorflow",
      
      bulletpoints: [
        "Built a neural network model to classify IMDb movie reviews as positive or negative, using a dataset of 25,000 polar reviews",
        "Utilized TensorFlow for training a sequential neural network. The reviews were preprocessed (standardized to 250 words, vocabulary size set to 88,000) and encoded into integer sequences using a custom word index",
        "Model architecture consists of embedding layer, global average pooling layer, dense layer, and output layer",
        "Trained on a split dataset (90% training, 10% validation) for 40 epochs, achieving accuracy of 99.9% on a personal review.",
      ],
    },
    {
      title: 'NLP Keyword Context Tracker',
      img: require('../assets/img/projects/magnifyingGlass.jpg'),
      repo:"https://github.com/ngtr6788/cohere-htn",
      
      bulletpoints: [
        "Developed a tool to search PDFs by keywords, clustering their meanings to return the top 3 relevant paragraphs, enhancing document searchability",
        "Using Python, Cohere NLP API, Annoy, Pandas, React.js, Flask – The user inputs a PDF file and keywords. The tool preprocesses the data, uses semantic search to compare keyword vectors with paragraph embeddings, and returns the most relevant paragraphs with page numbers",
      ],
    },
    {
      title: 'Web Scraper for Collectible Prices',
      img: require('../assets/img/projects/webscraper.png'),
      
      repo: "https://github.com/Aiden-Robinson/Web-Scraper-for-Collectable-Prices",
      bulletpoints: [
        "Developed an automated web scraper to track the prices of collectible items (e.g., Pokémon cards) across various websites, saving an hour of manual work weekly",
        "The script fetches HTML from URLs, isolates price data using Beautiful Soup, processes it into structured tables, and sorts the data by price using Pandas for easy viewing",
      ],
    },
  ]
}

// Biomedical Projects SECTION
const hardwareprojects = {
  show: true,
  heading: "Hardware Projects",
  data: [
    {
      title: 'High Voltage Controller Respin',
      img: require('../assets/img/projects/HVController.jpg'),
      bulletpoints: [
        "Redesigned the high voltage safety controller, meeting Formula SAE Electric (FSAE) standards through iterative improvements based on competition feedback",
        "Led schematic design, PCB routing, part sourcing, manufacturing, and testing",
        "Enhanced circuit modularity for easier testing and integrated LED indicators for diagnostic clarity",
      ],
    },
    {
      title: 'PWM Relay Economizer',
      img: require('../assets/img/projects/economizer.png'),
      bulletpoints: [
        "Designed and manufactured relay economizer board using Altium Designer to be used on contactors throughout the car",
        "Optimizes solenoid power consumption by utilizing Pulse Width Modulation (PWM) to supply just enough current to keep contactors closed rather than continuously sending current in",
        "Measured activation and hold phases of components and chose components accordingly",
      ],
    },
    {
      title: 'Sequential Logic Design, 2E04 Project',
      img: require('../assets/img/projects/breadboard.png'),
      
      // repo: "",
      bulletpoints: [
        "Designed and built a digital circuit using J-K flip-flops to display my student number on a 7-segment display, with a custom solution for tracking repeated digits in the binary sequence",
        "Created optimized truth tables and Karnaugh maps (K-maps) to derive logic expressions for each J-K flip-flop input, resulting in both SOP and POS logic models",
        "Simulated the final circuit using NI Multisim, achieving a clean timing diagram matching expected binary outputs for the display",
        "Assembled and tested the physical circuit with organized, color-coded wiring for clarity, successfully achieving real-time display of my student number",
      ],
    },


  ]
}

// Leadership SECTION
const leadership = {
  show: false,
  heading: "Leadership",
  message:
    'My journey has contained many leadership opportunities, each contributing to my growth as both a leader and a team player. Highlights of these experiences can be seen below:<br><br>• Team Lead for MAC Formula Electric’s Suspension & Steering sub-team, leading a team of 12 members in designing, justifying, and manufacturing components for a 1/4 scale formula-style race car<br>• Microsoft Student Ambassador, organizing an event for 40 high school students to teach them how to create and host websites using various GitHub tools<br>• Panelist at McMaster\'s Leadership Development Conference and my high school\'s Computer Science Panel, sharing insights and expertise<br>• Founded and led my high school\'s inaugural team in a sumobot competition at Ontario Tech University, earning the "Best New Entry" award for our innovative use of the gyro sensor<br>• Co-captain and Head Driver of FRC Team 6110, guiding a team of 40 members after transferring due to funding cuts<br>• Head Driver and Mechanical/Electrical Lead of FIRST Robotics Team 5076, developing leadership and technical skills in a robotics context',
  images: [
    // { 
    //   img: require("../assets/img/leadership/fsae_1.JPG"), 
    //   label: "FSAE Formula Electric 2023", 
    //   paragraph: "Michigan International Speedway" 
    // },
    // { 
    //   img: require("../assets/img/leadership/fsae_2.jpeg"), 
    //   label: "FSAE Formula Electric 2023", 
    //   paragraph: "Michigan International Speedway" 
    // },
  ],
  // imageSize: {
  //   width:"615",
  //   height:"450"
  // }
};

// SKILLS SECTION
const skills = {
  show: true,
  heading: "Skills",
  codingSkills: [
    { name: "Python", value: 90 },
    { name: "C / C++", value: 80 },
    { name: "Go", value: 65 },
    { name: "SQL", value: 65 },
    { name: "Java", value: 65 },
    { name: "React Native", value: 50 },
    { name: "Git", value: 85 },
    { name: "Google Cloud Platform", value: 90 },
    
  ],
  
  AISkills: [
    { name: "TensorFlow", value: 80 },
    { name: "Pytorch", value: 65 },
    { name: "OpenCV", value: 95 },
    { name: "Langchain", value: 90 },
    { name: "OpenAI Gym", value: 70 },
  
  ],
};

// GET IN TOUCH SECTION
const getInTouch = {
  show: true,
  heading: "Contact",
  message:
    "I'm currently looking for Software Engineering or Machine Learning Internships. Please feel free to email me at",
  email: "aiden.robinson1@gmail.com",
};

// const experiences = {
//   show: true,
//   id: "experience",
//   heading: "Work Experience",
//   data: [
//     {
//       role: 'AI/Cloud Engineer Intern',
//       company: "TELUS",
//       team: "Team: Workflow Automations",
//       companylogo: require('../assets/img/telus2.jpg'),
//       date: 'May 2024 – August 2024',
//       //description: `Led development for an image recognition model for network equipment to automate email personnel monitoring for ladder truck requests.Designed a new architecture for a multimodal chatbot to enhance Retrieval-Augmented Generation (RAG) processes and improve response times.Created an API endpoint to enable real-time internet responses for a chatbot, and implemented a CI/CD pipeline to deploy the solution on Google Kubernetes Engine (GKE).Automated the workflow for the construction team by developing a solution that uses Generative AI to extract key information from emails and log it in Google Sheets, incorporating robust error handling mechanisms`
//     },
//     {
//       role: 'AI/Cloud Engineer Intern',
//       company: "TELUS",
//       team: "Team: Energy Management",
//       companylogo: require('../assets/img/telus2.jpg'),
//       date: 'May 2023 – May 2024',
//       //description: "Initiated and designed AI/cloud architecture for predictive maintenance of network equipment. Implemented an energy optimization reinforcement learning model for a data center HVAC system. Performed unsupervised learning on battery data to be used in a business case to reduce maintenance scheduling. QA tested LLM chatbot and contributed to the AI governance plan surrounding it. Benchmarked software for nonlinear structural analysis of utility poles. Planned data center equipment floor plans."
//     },
//     {
//       role: 'Teaching Assistant',
//       company: "McMaster University",
//       team: "1Z03 Artificial Intelligence",
//       companylogo: require('../assets/img/mcmaster-logo.jpeg'),
//       date: 'January 2023 – Present',
//       //description: "Assisted in teaching AI concepts and provided support to students in their projects."
      
//     },
//   ]
// }

const experiences = { //duplicated version
  show: true,
  id: "work-experience",
  heading: "Work Experience",
  data: [
    {
      role: 'AI/Cloud Engineer Intern',
      company: "TELUS",
      team: "Team: Workflow Automations",
      companylogo: require('../assets/img/telus2.jpg'),
      date: 'May 2024 – August 2024',
      //description: `Led development for an image recognition model for network equipment to automate email personnel monitoring for ladder truck requests.Designed a new architecture for a multimodal chatbot to enhance Retrieval-Augmented Generation (RAG) processes and improve response times.Created an API endpoint to enable real-time internet responses for a chatbot, and implemented a CI/CD pipeline to deploy the solution on Google Kubernetes Engine (GKE).Automated the workflow for the construction team by developing a solution that uses Generative AI to extract key information from emails and log it in Google Sheets, incorporating robust error handling mechanisms`
    },
    {
      role: 'AI/Cloud Engineer Intern',
      company: "TELUS",
      team: "Team: Energy Management",
      companylogo: require('../assets/img/telus2.jpg'),
      date: 'May 2023 – May 2024',
      //description: "Initiated and designed AI/cloud architecture for predictive maintenance of network equipment. Implemented an energy optimization reinforcement learning model for a data center HVAC system. Performed unsupervised learning on battery data to be used in a business case to reduce maintenance scheduling. QA tested LLM chatbot and contributed to the AI governance plan surrounding it. Benchmarked software for nonlinear structural analysis of utility poles. Planned data center equipment floor plans."
    },
    {
      role: 'Teaching Assistant',
      company: "McMaster University",
      team: "1Z03 Artificial Intelligence",
      companylogo: require('../assets/img/mcmaster-logo.jpeg'),
      date: 'January 2023 – Present',
      //description: "Assisted in teaching AI concepts and provided support to students in their projects."
      
    },
  ]
}

const extracurriculars = {
  show: true,
  id: "extracurriculars",
  heading: "Extracurriculars",
  data: [
    {
      role: 'Vice President of Technical',
      team: "McMaster Artificial Intelligence Society",
      customWidth: "75%",
      company: "MAC Formula Electric",
      link: "https://www.mcmasterai.com/",
      companylogo: require('../assets/img/macailogo.png'),
      date: 'September 2022 – Present',
      //description: "Overseeing 50+ students across 5 technical teams, and the educational workshop division"
    },
    {
      role: 'Firmware and Electrical Subteam Member',
      team: "MAC Formula Electric",
      company: "McMaster Engineering Society",
      link: "https://macformularacing.com/",
      companylogo: require('../assets/img/formulaelectric.png'),
      date: 'November 2021- Present',
      //description: "Redesigned the high voltage safety system and PCB for the car using Altium Designer. Responsible for schematic design, part sourcing, PCB routing, manufacturing, and testing. Tested, attached, and documented wire harness connections."
    },
  ]
}

// Blog SECTION
// const blog = {
//   show: false,
// };

export { navBar, mainBody, about, repos, skills, leadership, getInTouch, experiences, projects, extracurriculars, hardwareprojects };
